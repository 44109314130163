import { render, staticRenderFns } from "./ThemeCustomizeView.vue?vue&type=template&id=10fae730&scoped=true"
import script from "./ThemeCustomizeView.vue?vue&type=script&lang=js"
export * from "./ThemeCustomizeView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fae730",
  null
  
)

export default component.exports