<template>
    <v-main>
        <div class="main-content d-flex flex-column mt-0">
            <Card class="card-custom br-0">
                <template #title>
            <v-tabs
            v-model="selectedTab"
            class="main-tabs"
            background-color="var(--white)"
        >
          <v-tab v-if="!isAgent" to="/themes/admin">
            takemybet.pro
          </v-tab>
          <v-tab to="/themes/agent">
            {{ domain }}
          </v-tab>
        </v-tabs>
    </template>
    <template #content>
        <router-view> </router-view>
    </template>
</Card>
</div>
</v-main>
</template>

<script>
import { mapGetters } from "vuex";
    export default {
        name: 'ThemeCustomizeView',
        data() {
            return {
                domain: window.location.hostname.includes("take")
                ? process.env.VUE_APP_API_DOMAIN_FIRST
                : process.env.VUE_APP_API_DOMAIN_SECOND,
                selectedTab: 0
            }
        },
        computed:{
            ...mapGetters({
                tokenUser: 'tokenUser',
            }),
            isAgent() {
                return this.tokenUser === 'agent';
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>